input {
    padding: 10px;
    border-radius: 10px;
    border-width: 3px;
    border-color: #e8e8e8;
    font-size: 30;
    font-weight: 600;
    text-align: center;
    color: #e8e8e8;
    outline: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
    background: none;
    caret-color: #335c4c;
}

input[type="number"]::-webkit-outer-spin-button,input[type="number"]::-webkit-inner-spin-button{
    -webkit-appearance: none;
}

.addtoken {
    background-color: #e8e8e8;
    color: #1C1C1C;
}

.addNet {
    padding: 5px 10px;
    margin: 10px;
    font-size: 18px;
    border-radius: 25px;
    font: inherit;
    cursor: pointer;
}